import React, { memo } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import Casebanner from 'components/Casebanner/Casebanner'
import { fitcomProps } from 'components/Props/fitcom'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Fasterdevelopmentsec from 'components/Fasterdevelopmentsec/Fasterdevelopmentsec'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Appplatformsec from 'components/Appplatformsec/Appplatformsec'
import Fitcomappscreensec from 'components/Fitcomappscreensec/Fitcomappscreensec'
import Resultimgsection from 'components/Resultimgsection/Resultimgsection'
import { xl, lg, md, xll } from 'config/variables'
import Caseintegrate from 'components/Caseintegrate/Caseintegrate'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const FitcomStyles = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #b7b1a5;
          }
        }
      }
    }
  }
  .Resultimgsection {
    margin-bottom:120px;
    overflow:hidden;
    ${xl(`
      margin-bottom:90px;
    `)}
    ${lg(`
       margin-bottom:60px;
    `)}
    ${md(`
        margin-bottom:40px;
    `)}
    .container {
      .content-blk{
        flex: 0 1 533px;
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
      
    }
    .img-blk {
      bottom:0;
      top:0px;
      width:741px;
      margin:0 auto;
      display:flex;
      right:-10px;
      ${xll(`
          width:641px;
      `)}
      ${xl(`
          right:0px;
          width:100%;
      `)}
      ${lg(`
          right:0px;
          width:100%;
      `)}
      .imageWrap {
        flex: 0 0 812px;
        margin:auto;
        ${xll(`
            flex: 0 0 712px;
        `)}
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
    }
  }
  .AppplatformsecStyles {
    margin-bottom:120px;
    ${xl(`
      margin-bottom:90px;
    `)}
    ${lg(`
       margin-bottom:60px;
    `)}
    ${md(`
        margin-bottom:40px;
    `)}
  }
`
const Fitcom = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')

  let item = [props.data.mixpanel.childImageSharp.fluid]
  let itembottom = [props.data.mixpanel1.childImageSharp.fluid]
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Fitcom"
        description="Simform brought Fitcom's vision to reality in the form of a SaaS platform that allows fitness studios to build custom iOS & Android apps."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/fitcom/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/fitcom/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <FitcomStyles>
        <Casebanner bannerdata={fitcomProps} {...props} showFullimages={true} />
        <Caseintroduction
          caseintrodata={fitcomProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Fasterdevelopmentsec
            FasterdevelopmentData={fitcomProps.Fasterdevelopmentprops}
            {...props}
          />
          <Caseresultsec
            caseresultsecdata={fitcomProps}
            {...props}
            showImages={false}
          />
        </div>
        {showContent && (
          <>
            <Appplatformsec AppplatformData={fitcomProps} />
            <Fitcomappscreensec FitcomappscreenData={fitcomProps} {...props} />
            <Reliabilityinstancessec
              ReliabilityinstancesData={
                fitcomProps.ReliabilityinstancesTopProps
              }
              {...props}
              fluid={itembottom}
            />
            <Resultimgsection resultimgsectiondata={fitcomProps} {...props} />
            <Caseintegrate caseintegratedata={fitcomProps} {...props} />
            <Reliabilityinstancessec
              ReliabilityinstancesData={fitcomProps.ReliabilityinstancesProps}
              {...props}
              fluid={item}
            />
            <Relatedcaselist relatedcaselistdata={fitcomProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={fitcomProps} />
          </>
        )}
      </FitcomStyles>
    </Layout>
  )
})
export default Fitcom

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/fitcom-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    microservicesArchitecture: file(
      relativePath: { regex: "/faster-development-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    redbulleventscreen: file(
      relativePath: { regex: "/fitcom-cms-deashboard@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 812) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    integrateImg: file(relativePath: { regex: "/testing-diagram@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(
      relativePath: { regex: "/reliability-instances-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel2: file(
      relativePath: { regex: "/reliability-instances-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel1: file(
      relativePath: { regex: "/reliability-vonage-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/tryg-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/decora-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen1: file(
      relativePath: { regex: "/fitcom-app-screens1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen2: file(
      relativePath: { regex: "/fitcom-app-screens2@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen3: file(
      relativePath: { regex: "/fitcom-app-screens3@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen4: file(
      relativePath: { regex: "/fitcom-app-screens4@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen5: file(
      relativePath: { regex: "/fitcom-app-screens5@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen6: file(
      relativePath: { regex: "/fitcom-app-screens6@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen7: file(
      relativePath: { regex: "/fitcom-app-screen7@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    FitcomAppScreen8: file(
      relativePath: { regex: "/fitcom-app-screen8@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
